import { DocumentContent } from '../tasks/DocumentContent';
import { Rule } from './Rule';
import { RuleConfiguration } from './RuleConfiguration';
import { RuleLibraryContentResult } from './RuleLibraryContentResult';
import { RuleResultChange } from './RuleResultChange';
import { RuleTextContentResult } from './RuleTextContentResult';

export interface RuleResult
{
  id?: string;
  rowVersion?: string;
  rule: Rule;
  applied: boolean;
  error: boolean;

  // Related Clasess
  ruleConfig?: RuleConfiguration;
  ruleConfigId?: string;
  documentContent?: DocumentContent;
  documentContentId?: string;
  documentVersionId?: string;
  subTaskId?: string;

  // Additional information needed to display the complete results on the web
  description: string;
  configInfo?: string;
  content?: string;
  failCause?: string;
  isFromTask?: boolean;
  status?: RuleResultStatus;

  textContentResults?: RuleTextContentResult[];
  libraryContentResults?: RuleLibraryContentResult[];

  ruleResultChanges?: RuleResultChange[];

  // UI Only
  filePath?: string;
  busy?: boolean;
  config?: any;
  data?: any; // can be used to store test results
}

export enum RuleResultStatus
{
  // TODO: Define this list
  empty = 'Empty',
  error = 'Error',
  ok = 'Ok',
  na = 'NA',
  pending = 'Pending'
}
