import { Injectable } from '@angular/core';
import {
  DocumentVersion,
  MoonDeskDocument,
  Rule,
  RuleIDs,
  UserCheckedRuleConfiguration
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import {
  RuleResult,
  RuleResultStatus
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';

@Injectable({
  providedIn: 'root'
})
export class UserCheckedRule implements Rule {

  constructor() { }

  id = RuleIDs.USER_CHECKED;
  name: string = 'User check';

  actionName: string = 'Show';

  async runRule(
    document: MoonDeskDocument,
    docVersion: DocumentVersion,
    filePath: string,
    config: any): Promise<RuleResult>
  {
    const userCheckConfig: UserCheckedRuleConfiguration = config;
    const result: RuleResult =
    {
      rule: this,
      filePath: filePath,
      applied: true,
      error: false,
      description: userCheckConfig.ruleDescription,
      status: RuleResultStatus.pending,
    };
    return result;
  }

  action = (result: RuleResult) =>
  {
    // todo
  };

  parseConfig (config: string): UserCheckedRuleConfiguration
  {
    let configuration: UserCheckedRuleConfiguration;
    if (config)
    {
      try
      {
        config = config.replace(/(?:\\r|\\n)/g, ' ');
        configuration = JSON.parse(config);
      }
      catch (error)
      {
        throw Error('Bad configuration json string');
      }
      return configuration;
    }
    throw Error('Bad configuration - the configuration can\'t be null');
  }
}
