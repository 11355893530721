import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { FrameComponent } from './frame/frame.component';
import { FilesystemService } from './services/filesystem.service';
import { IllustratorService } from './services/illustrator.service';
import { NavigationService } from './services/navigation.service';
import { WorkComponent } from './pages/work/work.component';
import { WorkManagerComponent } from './pages/work/work-manager/work-manager.component';
import { CurrentDocumentComponent } from './pages/work/current-document-manager/current-document.component';
import { SaveDocumentComponent } from './pages/work/save-document/save-document.component';
import { WorkHeaderComponent } from './pages/work/work-header/work-header.component';
import { AddDocToTaskDialogComponent } from './pages/_shared/add-doc-to-task-dialog/add-doc-to-task-dialog.component';
import { ConfigurationComponent } from './pages/user/configuration/configuration.component';
import { BackendSmartMappingService } from './services/backend-smart-mapping.service';
import { IllustratorConfigService } from './services/illustrator-config.service';
import { DocumentSharingService } from './services/document-sharing.service';
import { DownloadDocumentsComponent } from './pages/_shared/download-documents/download-documents.component';
import { PostSavePopupComponent } from './pages/_shared/post-save-popup/post-save-popup.component';
import { MoonDeskConfigService } from './services/moon-desk-config.service';
import { BulkImportComponent } from './pages/_shared/bulk-import/bulk-import.component';
import { MoonDeskLibServiceModule } from '../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/moondesk-web-lib.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { BulkService } from './services/bulk.service';
import { LibraryComponent } from './pages/library/library.component';
import { LibraryCardComponent } from './pages/library/library-card/library-card.component';
import { LibraryCacheService } from './services/library-cache.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NewVersionDialogComponent } from './pages/_shared/new-version-dialog/new-version-dialog.component';
import { VersionCheckService } from './pages/_shared/new-version-dialog/version-check.service';
import { FieldListComponent } from './pages/_shared/field-list/field-list.component';
import { TagListComponent } from './pages/_shared/tag-list/tag-list.component';
import { InfoPopupComponent } from './pages/_shared/info-popup/info-popup.component';
import { ShareWarningDialogComponent } from './pages/_shared/share-warning-dialog/share-warning-dialog.component';
import { ProgressControlComponent } from './pages/_shared/progress-control/progress-control.component';
import { DocumentContentsComponent } from './pages/_shared/document-contents/document-contents.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TooltipComponent } from './pages/_shared/tooltip/tooltip.component';
import { TooltipDirective } from './pages/_shared/tooltip/tooltip.directive';
import { SidenavContentComponent } from './pages/sidenav-content/sidenav-content.component';
import { NotificationBellComponent } from './pages/notifications/notification-bell/notification-bell.component';
import { IdentityComponent } from './pages/user/identity/identity.component';
import { LoginComponent } from './pages/user/login/login.component';
import { RegisterComponent } from './pages/user/register/register.component';
import { CompanySelectorComponent } from './pages/user/company-selector/company-selector.component';
import { UserInfoComponent } from './pages/user/user-info/user-info.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { SearchComponent } from './pages/search/search.component';
import { ClassSelectorDialogComponent } from './pages/class-selector-dialog/class-selector-dialog.component';
import { ClassSelectorComponent } from './pages/class-selector/class-selector.component';
import { AddClassValueDialogComponent } from './pages/class-selector/add-class-value-dialog/add-class-value-dialog.component';
import { AngularSplitModule } from 'angular-split';
import { DocumentCardComponent } from './pages/search/document-card/document-card.component';
import { TagEditDialogComponent } from './pages/_shared/tag-edit-dialog/tag-edit-dialog.component';
import { AskDialogComponent } from './pages/_shared/ask-dialog/ask-dialog.component';
import { CompanyDialogComponent } from './pages/user/company-dialog/company-dialog.component';
import { TasksListComponent } from './pages/tasks/tasks-list/tasks-list.component';
import { TaskSearchComponent } from './pages/tasks/task-search/task-search.component';
import { MoonDatepickerHeaderComponent } from './pages/_shared/moon-datepicker/moon-datepicker-header.component';
import { TaskDescriptionPopupComponent } from './pages/tasks/task-description-popup/task-description-popup.component';
import { TaskHistoryComponent } from './pages/tasks/task-history/task-history.component';
import { FindReplaceComponent } from './pages/find-replace/find-replace.component';
import { PictureViewerComponent } from './pages/picture-viewer/picture-viewer.component';
import { NewMessageComponent } from './pages/notifications/new-message/new-message.component';
import { ImageZoomComponent } from './pages/image-zoom/image-zoom.component';
import { DocumentStatusPopupComponent } from './pages/_shared/document-status-popup/document-status-popup.component';
import { RulesDialogComponent } from './pages/rules/rules-dialog/rules-dialog.component';
import { DocumentAccessProvider } from '../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { DoubleSpaceRule } from './services/rules/rule.double-space';
import { DuplicateLabelRule } from './services/rules/rule.duplicate';
import { OverflowedTextItemRule } from './services/rules/rule.overflowed-textitem';
import { LocalLinksRule } from './services/rules/rule.local-link';
import { TextContentRule } from './services/rules/rule.text-content';
import { LibraryContentRule } from './services/rules/rule.library-content';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { SaveDocumentPopupComponent } from './pages/work/save-document-popup/save-document-popup.component';
import { ReviewersListComponent } from './pages/_shared/reviewers-list/reviewers-list.component';
import { RuleCreateComponent } from './pages/rules/rule-create/rule-create.component';
import { RulesComponent } from './pages/rules/rules.component';
// import { ResizableModule } from 'angular-resizable-element';
import { GuideToolTipComponent } from './pages/_shared/guide-tool-tip/guide-tool-tip.component';
import { PasteContentPopupComponent } from './pages/_shared/paste-content-popup/paste-content-popup.component';
import { AcceptLegalsComponent } from './frame/accept-legals/accept-legals.component';
import { TaskChatComponent } from './pages/tasks/task-chat/task-chat.component';
import { RulesExecutionService } from './services/rules/rules-execution.service';
import { DownloadDropdownComponent } from './pages/_shared/download-dropdown/download-dropdown.component';
import { FontsHandlerDialogComponent } from './pages/_shared/fonts-handler-dialog/fonts-handler-dialog.component';
import { FontsPickerComponent } from './pages/_shared/fonts-picker/fonts-picker.component';
import { MoonZoomComponent } from './pages/_shared/moonZoom/moon-zoom.components';
import { MarkdownModule } from 'ngx-markdown';
import { DocumentAIAnalysisComponent} from './pages/document-ai-analysis/document-ai-analysis.component';
import { LibraryNamingDialogComponent } from './pages/library/library-naming-dialog/library-naming-dialog.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true
};

export function httpLoaderFactory(http: HttpClient)
{
  const random = Math.random().toString(36).substring(5);
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster='
      + random);
}
@NgModule({
  exports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    OverlayModule,
    MatBadgeModule
  ],
  declarations: []
})
export class MaterialModule {}

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: 'DocumentAccessProvider', useClass: DocumentAccessProvider },
        RulesExecutionService,
        DoubleSpaceRule,
        DuplicateLabelRule,
        OverflowedTextItemRule,
        LocalLinksRule,
        TextContentRule,
        LibraryContentRule
    ]
})
export class RuleModule {}

@NgModule({
  declarations: [
    AppComponent,
    FrameComponent,
    ConfigurationComponent,
    FieldListComponent,
    TagListComponent,
    DownloadDocumentsComponent,
    PostSavePopupComponent,
    BulkImportComponent,
    WelcomeComponent,
    TaskDescriptionPopupComponent,
    LibraryComponent,
    LibraryCardComponent,
    NewVersionDialogComponent,
    WorkComponent,
    WorkManagerComponent,
    CurrentDocumentComponent,
    SaveDocumentComponent,
    WorkHeaderComponent,
    AddDocToTaskDialogComponent,
    InfoPopupComponent,
    ShareWarningDialogComponent,
    ProgressControlComponent,
    DocumentContentsComponent,
    TooltipComponent,
    TooltipDirective,
    SidenavContentComponent,
    NotificationBellComponent,
    IdentityComponent,
    LoginComponent,
    RegisterComponent,
    CompanySelectorComponent,
    UserInfoComponent,
    ProfileComponent,
    SearchComponent,
    ClassSelectorComponent,
    ClassSelectorDialogComponent,
    AddClassValueDialogComponent,
    DocumentCardComponent,
    TagEditDialogComponent,
    AskDialogComponent,
    CompanyDialogComponent,
    TasksListComponent,
    TaskSearchComponent,
    MoonDatepickerHeaderComponent,
    TaskHistoryComponent,
    FindReplaceComponent,
    PictureViewerComponent,
    NewMessageComponent,
    ImageZoomComponent,
    RulesDialogComponent,
    DocumentStatusPopupComponent,
    SaveDocumentPopupComponent,
    ReviewersListComponent,
    RuleCreateComponent,
    RulesComponent,
    GuideToolTipComponent,
    PasteContentPopupComponent,
    AcceptLegalsComponent,
    TaskChatComponent,
    DownloadDropdownComponent,
    FontsHandlerDialogComponent,
    FontsPickerComponent,
    MoonZoomComponent,
    DocumentAIAnalysisComponent,
    LibraryNamingDialogComponent
  ],
  imports: [
    MoonDeskLibServiceModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RuleModule,
    MaterialModule,
    ReactiveFormsModule,
    DragDropModule,
    AngularSplitModule.forRoot(),
    // ResizableModule,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: httpLoaderFactory, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    })
  ],
  providers: [ IllustratorService,
               IllustratorConfigService,
               FilesystemService,
               BackendSmartMappingService,
               NavigationService,
               DocumentSharingService,
               MoonDeskConfigService,
               BulkService,
               {provide: 'PlatformInfoProvider', useExisting: IllustratorConfigService},
               {provide: 'UserProvider', useExisting: IllustratorConfigService},
               {provide: 'DocumentAccessProvider', useExisting: IllustratorService},
               LibraryCacheService,
               VersionCheckService,
               { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
              ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
