import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { RuleResult } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import {
        ContentTextRuleConfiguration,
        RuleIDs,
        MoonDeskDocument,
        RuleConfiguration,
        DocumentVersion,
        MoonTask,
        FeedbackService,
        AuthService,
        ContentLibraryRuleConfiguration,
        TextsFormattingService,
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { IllustratorService } from '../../../services/illustrator.service';
import { RulesExecutionService } from '../../../services/rules/rules-execution.service';
import { SaveDocumentService } from '../../work/save-document/save-document.service';
import { DocumentContentsService } from 'src/app/services/document-contents.service';
import { RuleHelperService } from 'src/app/services/rule-helper.service';

export interface RuleDialogData
{
  confirm: boolean;
  filepath: string;

  /**
   * Just for show the results
   */
  ruleResults?: RuleResult[];
  rulesConfiguration?: RuleConfiguration[];
  /**
   * Needed for run Rules and show the results
   */
  suppressDuplicateCheck?: boolean;
  suppressFontsCheck?: boolean;
  document?: MoonDeskDocument;
  docVersion?: DocumentVersion;
  task?: MoonTask;
}
export interface RuleCard
{
  // Header
  name: string;
  richTextName?: string;
  status: 'error' | 'warning' | 'pending' |'ok';
  // Body
  description?: string;
  ruleContents?: RuleContent[]; // Only for text-content or library-content rules
  failCause?: string;

  caseSensitive?: boolean;
  toggled?: boolean;
  searchText?: string;
  ruleID?: RuleIDs;
  busy?: boolean;
}

interface RuleContent
{
  uiText: string;
  contentData: string; // text with bold marks or image
  found?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rules-dialog',
  templateUrl: './rules-dialog.component.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('100ms ease-in-out')
      ]),
      transition(':leave', [
        animate('100ms ease-in-out', style({ opacity: 0, height: 0 }))
      ]),
      state('*', style({ opacity: 1 })),
    ])
  ],
  styleUrls: ['./rules-dialog.component.scss'],
  // To apply css styles/classes in [innerHTML] (used in rule contents)
  encapsulation: ViewEncapsulation.None,
})
export class RulesDialogComponent
{
  confirm: boolean;

  filepath: string;
  document: MoonDeskDocument;
  docVersion: DocumentVersion;
  task: MoonTask;

  rulesConfiguration: RuleConfiguration[];

  loadingConfig: boolean;
  loadingRules: boolean;

  hasError: boolean;
  hasContentError: boolean;
  hasDuplicateError: boolean;

  disabledTooltip: string;

  taskRules: RuleCard[] = [];
  workspaceRules: RuleCard[] = [];

  ruleResults: RuleResult[] = [];

  listenSelectionChange: boolean = false;
  documentChanged: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RulesDialogComponent>,
    public illService: IllustratorService,
    public rulesExecutionService: RulesExecutionService,
    public feedbackService: FeedbackService,
    public saveDocService: SaveDocumentService,
    private authService: AuthService,
    private docContentsService: DocumentContentsService,
    private textsFormattingService: TextsFormattingService,
    private rulesHelperService: RuleHelperService,
    @Inject(MAT_DIALOG_DATA) public data: RuleDialogData)
  {
    this.loadData(data);
    this.illService.SelectionChangedEvent.subscribe(() => this.setDocChanged());
  }

  setDocChanged()
  {
    if (this.listenSelectionChange)
    {
      this.documentChanged = true;
    }
  }

  private async setListenSelectionChange(listen: boolean)
  {
    if (listen)
    {
      // Not nice, but we have to wait for any possible illustrator events (findText, findDoubleSpaces) to finish
      // before setting listenSelectionChange to true
      await this.wait(2000);
    }
    this.listenSelectionChange = listen;
  }

  private wait(ms: number): Promise<void>
  {
    return new Promise<void>(res => setTimeout(() => res(), ms));
  }

  async rerunRules()
  {
    this.documentChanged = false;
    this.setListenSelectionChange(false);
    this.taskRules = [];
    this.workspaceRules = [];
    this.ruleResults = [];
    try
    {
      const docItems = await this.illService.getVisibleItems(this.filepath, 0);
      this.docVersion = await this.saveDocService.createDocumentVersion(this.document, docItems, this.filepath);
      await this.runAllRules(this.rulesConfiguration);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error running rules', err);
    }

    this.setListenSelectionChange(true);
  }

  private async loadData(data: RuleDialogData)
  {
    this.setListenSelectionChange(false);
    this.loadingConfig = true;
    this.filepath = data.filepath;
    this.document = data.document;
    this.docVersion = data.docVersion;
    this.task = data.task;
    this.confirm = data.confirm;
    if (data.ruleResults)
    {
      for (const ruleResult of this.data.ruleResults)
      {
        this.rulesConfiguration = data.rulesConfiguration;
        this.addRuleResult(ruleResult);
        this.setListenSelectionChange(true);
      }
    }
    else
    {
      try
      {
        this.rulesConfiguration =
          await this.rulesExecutionService.getRulesConfig(this.document, this.task, data.suppressDuplicateCheck, data.suppressFontsCheck);
        this.runAllRules(this.rulesConfiguration).then(() => this.setListenSelectionChange(true));
      }
      catch (err)
      {
        this.feedbackService.notifyError('Error getting rules configuration', err);
      }
    }
    this.loadingConfig = false;
  }

  async runAllRules(rulesConfiguration: RuleConfiguration[])
  {
    this.loadingRules = true;
    for (const ruleConfig of rulesConfiguration)
    {
      const ruleResult = await this.runRule(ruleConfig);
      this.addRuleResult(ruleResult);
    }
    this.loadingRules = false;
  }

  async runRule(ruleConfig: RuleConfiguration): Promise<RuleResult>
  {
    if (!this.docVersion.fullTextCharacters)
    {
      this.docVersion.fullTextCharacters = await this.illService.getAllTextWithBoldReferences(this.document.workingCopy);
    }
    const ruleResult = await this.rulesExecutionService.runRule(ruleConfig, this.document, this.docVersion, this.filepath);
    return ruleResult;
  }

  private addRuleResult(ruleResult: RuleResult)
  {
    if (ruleResult.applied)
    {
      if (ruleResult.error)
      {
        this.hasError = true;
        if (ruleResult.rule && ruleResult.rule.id === 'TEXT_CONTENT')
        {
          this.hasContentError = true;
        }
        else if (ruleResult.rule && ruleResult.rule.id === RuleIDs.DUPLICATE_DOCUMENTS)
        {
          this.hasDuplicateError = !this.isDuplicateAllowed();
        }
      }
      const ruleCard = this.createRuleCard(ruleResult);
      ruleResult.description = ruleCard.richTextName ? ruleCard.richTextName : ruleCard.name;

      ruleResult.configInfo = ruleCard.description;
      const ruleContentHTML = ruleCard.ruleContents?.map(rc => rc.uiText).join(' ');
      if (ruleContentHTML)
      {
        ruleResult.configInfo = ruleContentHTML + ruleResult.configInfo;
      }

      ruleResult.failCause = ruleCard.failCause;
      if(ruleResult.isFromTask)
      {
        this.taskRules.push(ruleCard);
      }
      else
      {
        this.workspaceRules.push(ruleCard);
      }

      if (this.task)
      {
        ruleResult.subTaskId = this.task.subTasks?.find(st =>
          st.documentId === this.document.id)?.id;
      }
    }
    this.ruleResults.push(ruleResult);
  }

  ruleClicked(ruleCard: RuleCard)
  {
    ruleCard.toggled = !ruleCard.toggled;
    if (!ruleCard.toggled)
    {
      return;
    }
    if (ruleCard.searchText === '  ')
    {
      this.findDoubleSpaces();
    }
    else
    {
      if (ruleCard.ruleContents && ruleCard.ruleContents.length > 0)
      {
       //  this.findContents(ruleCard);
      }
      else
      {
        // this.findText(ruleCard.searchText, ruleCard.caseSensitive);
      }
    }
  }

  onNoClick(): void
  {
    this.dialogRef.close();
  }

  enterPressed(): void
  {
    if (this.hasError)
    {
      this.dialogRef.close();
    }
    else
    {
      this.onYesClick();
    }
  }

  onYesClick()
  {
    const resultsWithoutUserCheck = this.ruleResults.filter(rr => rr.rule.id !== RuleIDs.USER_CHECKED);
    this.dialogRef.close(resultsWithoutUserCheck);
  }

  isDuplicateAllowed(): boolean
  {
    const identity = this.authService.getCurrentIdentity();
    if (!identity.company.configuration.allowDuplicatedDocuments)
    {
      this.disabledTooltip = 'Duplicated documents are not allowed for this workspace';
      return false;
    }
    return true;
  }

  canSave(): boolean
  {
    return !this.hasDuplicateError && !this.loadingRules && !this.loadingConfig;
  }

  createRuleCard(ruleResult: RuleResult): RuleCard
  {
    const ruleCard: RuleCard =
    {
      name: ruleResult.description,
      status: this.getStatus(ruleResult),
      ruleID: ruleResult.rule.id
    };
    switch (ruleResult.rule.id)
    {
      case (RuleIDs.TEXT_CONTENT):
        const config: ContentTextRuleConfiguration = ruleResult.config as ContentTextRuleConfiguration;
        if (config)
        {
          const checkText = config.richCheckText ? config.richCheckText : config.checkText;
          // Format check text
          // const checkTextsStrings: string[] = [];
          ruleCard.ruleContents = [];
          const textContents = this.rulesHelperService.parseTextContents(checkText);
          textContents.forEach(tc =>
          {
            let redeableWildCard = '';
            switch (tc.wildCard)
            {
              case 'matchesWith':
                redeableWildCard = 'exist:';
                break;
              case 'contains':
                redeableWildCard = 'contain:';
                break;
              case 'startsWith':
                redeableWildCard = 'start with:';
                break;
              case 'endsWith':
                redeableWildCard = 'ends with:';
                break;
            }

            let redeableCondition = `${config.checkCondition === 'exist' ? 'should' : 'should not'} ${redeableWildCard} ${tc.text}`;

            if (tc.logicalConector)
            {
              const redeableLogicalConector = tc.logicalConector === '&&' ? 'and' : 'or';
              redeableCondition = `${redeableLogicalConector} ${redeableCondition}`;
            }
            if (ruleCard.ruleContents.length === 0)
            {
              redeableCondition = `- ${redeableCondition[0].toUpperCase() + redeableCondition.slice(1)}`;
            }
            const noHtml = this.textsFormattingService.formatHtml(tc.text, true, false, false);
            const cleanText = this.textsFormattingService.formatString(noHtml, true, false, true);
            const found = ruleResult.textContentResults?.find(r => r.content.trim().includes(cleanText.trim()))?.found;
            ruleCard.ruleContents.push({uiText: redeableCondition, contentData: tc.text, found: found});
          });
          const textSize = config.maximumTextSize && config.maximumTextSize !== 0 ? config.maximumTextSize : config.textSize;
          ruleCard.description = `- Case sensitive: ${config.checkTextCaseSensitive ? 'Yes' : 'No'}<br>` +
                                  `- ${config.maximumTextSize && config.maximumTextSize !== 0 ? 'Maximum height' : 'Minimum height'}: ` +
                                  `${textSize && textSize > 0 ? textSize + 'mm' : 'Unspecified'}`;
          ruleCard.searchText = config.checkText;
          ruleCard.failCause = ruleResult.data ? `${ruleResult.data}` : '';
          ruleCard.richTextName = config.richTextDescription;
          ruleCard.caseSensitive = config.checkTextCaseSensitive ? true : false;
        }
        break;
      case (RuleIDs.DUPLICATE_DOCUMENTS):
        if (ruleResult.error)
        {
          const duplicatedDocumentsNames: string[] = [];
          for (const name of ruleResult.data)
          {
            duplicatedDocumentsNames.push(name);
          }
          let failCause = 'Duplicate Documents:\n - ' + duplicatedDocumentsNames.join('\n - ');
          if (!this.isDuplicateAllowed())
          {
            failCause = failCause + '\nDuplicated documents are not allowed for this workspace';
          }
          ruleCard.failCause = failCause;
        }
        else
        {
          ruleCard.description = 'No duplicate documents found';
        }

        break;
      case (RuleIDs.LOCAL_LINKS):
        if (ruleResult.error)
        {
          ruleCard.failCause = 'Library is not activated for this workspace';
        }
        else
        {
          ruleCard.description = 'No missing linked files found';
        }
        break;
      case (RuleIDs.DOUBLE_SPACES):
        if (ruleResult.error)
        {
          ruleCard.failCause = `${ruleResult.data} double spaces were found within the text content`;
          ruleCard.searchText = '  ';
        }
        else
        {
          ruleCard.description = 'No double spaces found in the contents';
        }
        break;
      case (RuleIDs.OVERFLOWED_TEXTITEM):
        if (ruleResult.error)
        {
          ruleCard.failCause = `${ruleResult.data} overflowed were found within the text content`;
        }
        else
        {
          ruleCard.description = 'No overflowed text found in the contents';
        }
        break;
      case (RuleIDs.USER_CHECKED):
        const ruleConfig = this.rulesConfiguration.find(rc => rc.id === ruleResult.ruleConfigId);
        ruleCard.description = ruleConfig ? this.textsFormattingService.removeHtml(ruleConfig.description) : 'No additional info';
        ruleCard.status = 'pending';
        break;
      case (RuleIDs.LIBRARY_CONTENT):
        ruleCard.ruleContents = [];
        const libRuleConfig: ContentLibraryRuleConfiguration = ruleResult.config;
        libRuleConfig?.docVersions.forEach(dv =>
        {
          let condition: string;
          if (ruleCard.ruleContents.length === 0)
          {
            condition = `- Should exist:\n${dv.docName}`;
          }
          else
          {
            condition = `${libRuleConfig.logicalOperator} should exist:\n${dv.docName}`;
          }

          let sizeLogicalOperator: string;
          switch (dv.logicalOperatorSize)
          {
            case '<':
              sizeLogicalOperator = 'lower than';
              break;
            case '>':
              sizeLogicalOperator = 'bigger than';
              break;
            default: // =
              sizeLogicalOperator = 'of';
              break;
          }


          if (dv.height !== 0)
          {
            condition += `\nHeight ${sizeLogicalOperator} ${dv.height}mm`;
          }
          if (dv.width !== 0)
          {
            condition += `\nWidth ${sizeLogicalOperator} ${dv.width}mm`;
          }

          condition += '\n\n';

          const found = ruleResult.libraryContentResults?.find(r => r.versionId === dv.versionId)?.found;
          ruleCard.ruleContents.push({uiText: condition, contentData: dv.versionId, found: found});
        });
        if (ruleResult.error)
        {
          ruleCard.failCause = ruleResult.data;
        }
        else
        {
          ruleCard.description = libRuleConfig.logicalOperator === 'and' ?
            'The image(s) exist in the document' :
            'At least one of the images exist in the document';
        }
        break;
      case (RuleIDs.MISSING_FONTS):
        if (ruleResult.error)
        {
          const docMissing: string[] = [];
          for (const name of ruleResult.data)
          {
            docMissing.push(name);
          }
          let failCause = 'The following fonts could not be found:\n - ' + docMissing.join('\n - ');
          failCause = failCause + '\nIf the fonts were recently installed,\nplease restart Adobe Illustrator.';
          ruleCard.failCause = failCause;
        }
        else
        {
          ruleCard.description = 'Fonts found';
        }
        break;

      default:
        // Not implemented
        ruleCard.description = 'No additional info';
    }
    if (!ruleCard.description && !ruleCard.failCause)
    {
      // Should not happend
      console.log('RuleResult has missing data!');
      ruleCard.description = 'No additional info';
    }
    return ruleCard;
  }

  async findText(searchText: string, caseSensitive?: boolean)
  {
    if (!this.filepath || !searchText)
    {
      return;
    }
    this.setListenSelectionChange(false);
    this.illService.deselectDocumentElements(this.filepath);
    const searchPhrases = this.splitTextByAndOr(searchText);
    for (const phrase of searchPhrases)
    {
      let wholeWord = true;
      const cleanSearchText = this.removeWildcards(phrase);
      if (cleanSearchText !== phrase)
      {
        // The text has wildcards so we look for the text within other sentences
        wholeWord = false;
      }
      await this.illService.find(this.filepath, cleanSearchText, wholeWord, true, caseSensitive, 0);
    }
    await this.setListenSelectionChange(true);
  }

  async findContents(ruleCard: RuleCard, selectedContent?: RuleContent)
  {
    if (ruleCard.busy)
    {
      return;
    }
    try
    {
      ruleCard.busy = true;
      const ruleContents = selectedContent ? [selectedContent] : ruleCard.ruleContents;
      const caseSensitive = ruleCard.caseSensitive;
      if (!this.filepath || !ruleContents || ruleContents.length === 0)
      {
        return;
      }
      this.setListenSelectionChange(false);
      this.illService.deselectDocumentElements(this.filepath);
      if (ruleCard.ruleID === RuleIDs.TEXT_CONTENT)
      {
        for (const content of ruleContents)
        {
          const noHtml = this.textsFormattingService.formatHtml(content.contentData, true, false, false);
          const cleanSearchText = this.textsFormattingService.formatString(noHtml, true)?.trim();
          await this.illService.find(this.filepath, cleanSearchText, false, true, caseSensitive, 0);
        }
      }
      else if (ruleCard.ruleID === RuleIDs.LIBRARY_CONTENT)
      {
        const path = this.document.workingCopy;
        for (const content of ruleContents)
        {
          await this.illService.findLinkedImage(path, content.contentData, 0);
        }
      }
      else
      {
        throw Error('Invalid rule type');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error searching content', err);
    }
    finally
    {
      ruleCard.busy = false;
      await this.setListenSelectionChange(true);
    }
  }

  private splitTextByAndOr(text: string): string[]
  {
    const result: string[] = [];
    const orConditions: string[] = text.split(/\|\|/g).filter(Boolean);
    for (const orCondition of orConditions)
    {
      const andConditions: string[] = orCondition.split('&&');
      for (const phrase of andConditions)
      {
        result.push(phrase);
      }
    }
    return result;
  }

  private removeWildcards(dirtyText: string): string
  {
    let cleanText = dirtyText.replace(/wContains:/g, '');
    cleanText = cleanText.replace(/wStarts:/g, '');
    cleanText = cleanText.replace(/wEnds:/g, '');
    return cleanText.trim();
  }

  async findDoubleSpaces()
  {
    if (!this.filepath)
    {
      return;
    }
    this.setListenSelectionChange(false);
    this.illService.deselectDocumentElements(this.filepath);
    await this.illService.findDoubleSpaces(this.filepath, 0);
    await this.setListenSelectionChange(true);
  }

  private getStatus(ruleResult: RuleResult): 'error' | 'warning' | 'ok'
  {
    let status: 'error' | 'warning' | 'ok';
    if (ruleResult.error)
    {
      if (ruleResult.rule.id === RuleIDs.DUPLICATE_DOCUMENTS && this.hasDuplicateError)
      {
        status = 'error';
      }
      else
      {
        status = 'warning';
      }
    }
    else
    {
      status = 'ok';
    }
    return status;
  }

  getIcon(status: 'error' | 'warning' | 'pending' | 'ok'): 'error' | 'warning' | 'pending' | 'check_circle'
  {
    switch (status)
    {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'ok':
        return 'check_circle';
      case 'pending':
        return 'pending';
      default:
        // should not happend
        return 'check_circle';
    }
  }

  /**
   * @param content text content or document version id
   */
  async pasteContent(ruleCard: RuleCard, content: string)
  {
    if (ruleCard.busy)
    {
      return;
    }
    try
    {
      ruleCard.busy = true;
      if (ruleCard.ruleID === RuleIDs.TEXT_CONTENT)
      {
        await this.docContentsService.addTextContent(content, 0);
      }
      else if (ruleCard.ruleID === RuleIDs.LIBRARY_CONTENT)
      {
        await this.docContentsService.addLibContent(content);
      }
      else
      {
        throw Error('Invalid rule type');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error placing content', err);
    }
    finally
    {
      ruleCard.busy = false;
      // this.findContents(ruleCard);
    }
  }
}
