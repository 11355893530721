import { Injectable } from '@angular/core';

import * as _ from 'underscore';

import { DoubleSpaceRule } from './rule.double-space';
import { DuplicateLabelRule } from './rule.duplicate';
import { OverflowedTextItemRule } from './rule.overflowed-textitem';
import { TextContentRule } from './rule.text-content';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LocalLinksRule } from './rule.local-link';
import {
  Rule,
  LoggingService,
  TasksService,
  RuleIDs,
  MoonDeskDocument,
  DocumentVersion,
  MoonTask,
  RuleConfiguration,
  RulesService,
  AuthService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import {
  RuleResult,
  RuleResultStatus
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { LibraryContentRule } from './rule.library-content';
import { IllustratorService } from '../illustrator.service';
import { MissingFontsRule } from './rule.missing-fonts';
import { UserCheckedRule } from './rule.user-checked';


@Injectable({
  providedIn: 'root'
})
export class RulesExecutionService
{
  ruleImplementation: Rule[] = [];

  constructor(private dialog: MatDialog,
    private loggingService: LoggingService,
    private doubleSpace: DoubleSpaceRule,
    private overflowedTextItem: OverflowedTextItemRule,
    private duplicateLabel: DuplicateLabelRule,
    private localLinks: LocalLinksRule,
    private texContentCheckRule: TextContentRule,
    private ruleUserChecked: UserCheckedRule,
    private libraryContentCheckRule: LibraryContentRule,
    private tasksService: TasksService,
    private rulesService: RulesService,
    private authService: AuthService,
    private illService: IllustratorService,
    private missingFonts: MissingFontsRule)
    {
      this.ruleImplementation[RuleIDs.TEXT_CONTENT] = this.texContentCheckRule;
      this.ruleImplementation[RuleIDs.LIBRARY_CONTENT] = this.libraryContentCheckRule;
      this.ruleImplementation[RuleIDs.DOUBLE_SPACES] = this.doubleSpace;
      this.ruleImplementation[RuleIDs.OVERFLOWED_TEXTITEM] = this.overflowedTextItem;
      this.ruleImplementation[RuleIDs.LOCAL_LINKS] = this.localLinks;
      this.ruleImplementation[RuleIDs.DUPLICATE_DOCUMENTS] = this.duplicateLabel;
      this.ruleImplementation[RuleIDs.MISSING_FONTS] = this.missingFonts;
      this.ruleImplementation[RuleIDs.USER_CHECKED] = this.ruleUserChecked;
    }

  async runAllRules(document: MoonDeskDocument, docVersion: DocumentVersion,
                    filePath: string, suppressDuplicateCheck: boolean,
                    suppressFontsCheck: boolean, task?: MoonTask): Promise<RuleResult[]>
  {
    const rulesConfiguration: RuleConfiguration[] = await this.getRulesConfig(document, task, suppressDuplicateCheck, suppressFontsCheck);
    const ruleResults: RuleResult[] = [];
    for (const ruleConfig of rulesConfiguration)
    {
      const ruleResult = await this.runRule(ruleConfig, document, docVersion, filePath);
      ruleResult.subTaskId = task?.subTasks?.find(st => st.documentId === document.id)?.id;
      ruleResults.push(ruleResult);
    }
    return ruleResults;
  }

  async runRule(ruleConfig: RuleConfiguration, document: MoonDeskDocument,
                docVersion: DocumentVersion, filePath: string): Promise<RuleResult>
  {
    let rule: Rule;
    let ruleResult: RuleResult;
    try
    {
      this.illService.suppressEvents = true;
      rule = this.ruleImplementation[ruleConfig.ruleID];
      let implementationConfig: any;
      if (ruleConfig.ruleOptions)
      {
        implementationConfig = rule.parseConfig(ruleConfig.ruleOptions);
      }
      ruleResult = await rule.runRule(document, docVersion, filePath, implementationConfig);
    }
    catch (err)
    {
      this.loggingService.logException(err);
      let msg: string;
      if (err.message)
      {
        msg = err.message;
      }
      else if (err.error)
      {
        msg = err.error;
      }
      else
      {
        msg = err;
      }
      ruleResult = {
        rule: rule,
        filePath: filePath,
        error: true,
        applied: true,
        description: 'Error in rule ' + ruleConfig.ruleID + ' (' + msg + ')'
      };
    }
    finally
    {
      ruleResult.isFromTask = ruleConfig.isFromTask;
      ruleResult.ruleConfigId = ruleConfig.id;
      ruleResult.documentContentId = ruleConfig.documentContentId;
      ruleResult.documentVersionId = docVersion.id;
      ruleResult.status = !ruleResult.applied ? RuleResultStatus.empty : (ruleResult.error ? RuleResultStatus.error : RuleResultStatus.ok);
      this.illService.suppressEvents = false;
      return ruleResult;
    }
  }

  async getRulesConfig(
    document: MoonDeskDocument,
    task?: MoonTask,
    suppressDuplicateCheck?: boolean,
    suppressFontsCheck?: boolean): Promise<RuleConfiguration[]>
  {
    const id = this.authService.getCurrentIdentity();
    let rulesConfiguration: RuleConfiguration[] = await this.rulesService.getRulesConfigs(document);
    if (task && task.documentContents && task.documentContents.length > 0)
    {
      const findText = document.documentType ? document.documentType.name : ' ';
      const taskContentRules = this.tasksService.getRulesFromContent(task.documentContents, findText);
      rulesConfiguration = _.union(rulesConfiguration, taskContentRules);
    }
    if(id && !id.company.configuration?.allowDocumentsDoubleSpaces)
    {
      rulesConfiguration.push({companyId: undefined, ruleID: RuleIDs.DOUBLE_SPACES, ruleOptions: undefined});
    }

    if (!suppressDuplicateCheck)
    {
      rulesConfiguration.push({companyId: undefined, ruleID: RuleIDs.DUPLICATE_DOCUMENTS, ruleOptions: undefined});
    }

    rulesConfiguration.push({companyId: undefined, ruleID: RuleIDs.OVERFLOWED_TEXTITEM, ruleOptions: undefined});
    rulesConfiguration.push({companyId: undefined, ruleID: RuleIDs.LOCAL_LINKS, ruleOptions: undefined});

    if (!suppressFontsCheck)
    {
      rulesConfiguration.push({companyId: undefined, ruleID: RuleIDs.MISSING_FONTS, ruleOptions: undefined});
    }

    return rulesConfiguration;
  }
}

