<div class="document-contents" *ngIf="rulesGroups && rulesGroups.length > 0">
    <div class="contents-expansion-panel" *ngFor="let rulesGroup of rulesGroups">
        <div class="expansion-panel-header" (click)="rulesGroup.isExpanded = !rulesGroup.isExpanded">
            <span class="expansion-icon">
                <mat-icon class="notranslate" *ngIf="rulesGroup.isExpanded">expand_less</mat-icon>
                <mat-icon class="notranslate" *ngIf="!rulesGroup.isExpanded">expand_more</mat-icon>
            </span>
            <span class="title" [ngClass]="{'bold-title': rulesGroup.isExpanded}">{{rulesGroup.uiName}}</span>
        </div>
        <div class="expansion-panel-body" *ngIf="rulesGroup.isExpanded">
            <div class="content-groups" *ngFor="let contentGroup of rulesGroup.contentGroups">
                <span class="group-name" [matTooltip]="contentGroup.name">{{contentGroup.name}}</span>
                <div class="contents-container" *ngFor="let content of contentGroup.documentContents">
                    <div class="and-or-splitter" [ngClass]="{'and': content.previousRuleCondition === 'And', 'or': content.previousRuleCondition === 'Or'}" *ngIf="content.previousRuleCondition">
                        <span *ngIf="content.previousRuleCondition === 'And'" translate>lid.ext.pages._shared.document-contents.and</span>
                        <span *ngIf="content.previousRuleCondition === 'Or'" translate>lid.ext.pages._shared.document-contents.or</span>
                    </div>
                    <div class="content">
                        <div class="actions">
                            <span class="action paste-icon" (click)="linkContent(content)" *ngIf="!content.busy">
                                <mat-icon class="notranslate"  [matTooltip]="'lid.ext.pages._shared.document-contents.pasteContent' | translate" [inline]="true">content_paste_outline</mat-icon>
                            </span>
                            <span class="action paste-icon" (click)="linkContent(content)" *ngIf="content.busy">
                                <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'" color="accent"></mat-progress-spinner>
                            </span>

                            <span class="action check-rule-icon" (click)="checkRule(content)">
                                <mat-icon class="notranslate" [matTooltip]="'lid.ext.pages._shared.document-contents.checkRule' | translate" [inline]="true">check_circle_outline</mat-icon>
                            </span>
                        </div>
                        <div class="content-info" *ngIf="content.type === 'Text'">

                            <span class="info" *ngIf="content.wildCard">
                                {{getWildcardTranslation(content.wildCard)}}
                            </span>

                            <div class="text-content">
                                <span class="content-html-container" [innerHTML]="content.content"></span>
                            </div>

                            <span class="info" *ngIf="content.caseSensitve" translate>lid.ext.pages._shared.document-contents.caseSensitive</span>
                            <span class="info" *ngIf="content.minSize > 0 || content.maxSize > 0">{{getTextSizeControlString(content)}}</span>
                            <div class="details" *ngIf="content.description">
                                <span class="details-title" translate>lid.ext.pages._shared.document-contents.detail</span>
                                <span class="details-text">{{content.description}}</span>
                            </div>
                        </div>
                        <div class="content-info" *ngIf="content.type === 'LibraryElement'">
                            <div class="image-content">
                                <span class="image-container">
                                    <img [src]="content.documentVersion.imageUrl">
                                </span>
                                <span class="description">{{content.documentVersion.documentName}}</span>
                            </div>
                            <div class="info">
                                <span class="description-text">{{getImgSizeControlString(content)}}</span>
                            </div>
                            <div class="details" *ngIf="content.description">
                                <span class="details-title" translate>lid.ext.pages._shared.document-contents.detail</span>
                                <span class="details-text">{{content.description}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
